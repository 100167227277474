#root {
    font-family: 'Inter';
    font-size: 18px;
    --primary-color: #032340;
    --secondary-color: #70bba0;
    --light-green: #70bba01f;

}

button {
    cursor: pointer;
}

input {
    width: 100%;
    height: 46px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #48755F;
    padding: 0 2%;
    background-color: var(--light-green);
}

.custom-file-label {
    background-color: var(--light-green);
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 25vh;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

.spinner-grow {
    margin-right: 2%;
}

.btn {
    text-transform: uppercase;
    border-radius: 0;
}

.white-button {
    background: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #16192C;
    border-width: 0;
}

.white-button:hover {
    background: #007BFF;
    color: white;
    border: 2px solid white;
}

.blue-button {
    align-items: center;
    background: var(--primary-color);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.blue-button:hover {
    background: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.green-button, .green-border-button:hover {
    align-items: center;
    background: var(--secondary-color);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.green-button:hover {
    background: white;
    color: var(--primary-color);
    border: 2px solid var(--secondary-color);
}

.form-button {
    border: 2px solid transparent;
    width: 100%;
    margin-bottom: 5%;
}

.green-border-button {
    align-items: center;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #16192C;
    border: 2px solid var(--secondary-color);
}

.red-button, .red-border-button:hover {
    align-items: center;
    background: #FF2F64;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.red-button:hover {
    background: white;
    color: #16192C;
    border: 2px solid #FF2F64;
}

a.green-button {
    border: 2px solid transparent;
}

button:disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

button:disabled:hover {
    background-color: inherit !important;
}

.css-b62m3t-container {
    padding: 0;
}

@media (max-width: 400px) {
    .green-button, .green-border-button {
        padding: 3%;
    }
}
