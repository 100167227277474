.Sidebar {
    width: 20% !important;
    padding: 0 2%;
    min-height: 100vh;
    margin-left: 0;
    margin-top: 0;
    background: var(--light-green);
    display: block;
    position: relative;
    visibility: visible;
    transform: none !important;
    z-index: 0;
    text-align: center;
    color: var(--primary-color);
}

.vertical-detail-container {
    padding-bottom: 10%;
    border-bottom: 2px solid var(--primary-color);
}

.quick-stat-container {
    padding-top: 10%;
}

.quick-stat-container > .vertical-detail-container {
    padding-top: 10%;
    border-bottom: 2px solid var(--secondary-color);
}

.Logo {
    margin-top: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 38.093px;
    line-height: 59px;
}

.Logo > button {
    display: none;
}

.Sidebar-Image {
    display: initial;
    margin-right: 5%;
}

.Sidebar-Txt {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

.nav-pills .nav-link {
    color: white;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent;
    color: #007BFF !important;
    font-weight: bold;
    border-radius: 0;
}

.active-image {
    display: none;
}

.inactive-image {
    display: initial;
}

.nav-pills .nav-link.active > .active-image {
    display: initial;
    margin-right: 5%;
}

.nav-pills .nav-link.active > .inactive-image {
    display: none;
}

.nav-detail-container {
    width: 100%;
}

.tab-pane {
    padding: 2%;
}

.Cd_Section {
    display: flex;
    background: #FFFF;
}

.Accblnce_Card {
    display: flex;
    background: var(--primary-color);
    border-radius: 0;
    width: 25%;
    height: 24vh;
    padding: 2%;
}

.Member_Card {
    background: #FF2F64;
    border-radius: 0;
    width: 25%;
    height: 24vh;
    margin-left: 5%;
    padding: 2%;
}

.Acct_Balance {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.Card_In {
    width: 62px;
    height: 62px;
}

.Price {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #FFFFFF;
}

.Refill_Btn {
    width: 60%;
    height: 25%;
    margin-top: 5%;
}

.Member_Txt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.Date {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}

.Receno-Barr {
    background: var(--light-green);
    display: flex;
    padding: 2%;
    align-items: center;
}

.Recent_Number {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #27272E;
    margin-bottom: 0;
}

.Plcenew_Btn {
    margin-left: auto;
    padding: 1%;
}

.Tableee {
    background: #F2F5F7;
    align-items: center;
    width: 100%;
    margin-top: 2%;
}

tr {
    height: 52px;
}

tr th, tr td {
    padding: 0 2%;
}

.Theaddd {
    color: #414C59;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.Tbodyyy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #425466;
    background-color: #FFFFFF;
}

.BlueIcon {
    padding-right: 12px;
}

.Order_Btn {
    width: 10px;
    height: 10px;
    background: #EBEFF2;
    color: #EBEFF2;
    border-radius: 0;
    margin-right: 17px;
    border-width: 0;
}

.Carrier_Btn {
    width: 15px;
    height: 15px;
    background: #EBEFF2;
    color: #EBEFF2;
    border-radius: 0;
    border-width: 0;
    margin-right: 12px;
}

.More_Btn {
    width: 10px;
    height: 24px;
    background-color: #FFFFFF;
    padding-right: 29.55px;
    border-width: 0;
}

.Graph {
    width: 80%;
    margin-top: 5%;
}

.Card_Section {
    display: flex;
}

.Accblncee_Card {
    width: 25%;
    background: #4C6FFF;
    border-radius: 0;
    padding: 2%;
    display: flex;
}

.Note_Card {
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-left: 5px solid var(--primary-color);
    border-radius: 0;
    padding: 2%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AcctBalancee {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.Card_Icon {
    width: 62px;
    height: 62px;
}

.Pricee {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.Note {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #212933;
}

.Note_Details {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #212933;
}

.vertical-nav-button {
    display: none;
    color: white;
}

.status-detail-container {
    display: none;
}

.invoice-amount-column > svg {
    display: none;
}

@media (max-height: 800px) {
    .Accblnce_Card, .Member_Card, .Note_Card, .Accblncee_Card {
        height: auto !important;
    }

    .Refill_Btn {
        margin-bottom: 10% !important;
    }
}

@media (max-height: 750px) {
    .Sidebar {
        overflow: auto;
    }
}

@media (max-width: 1600px) {
    .portal-tab-container {
        width: 75% !important;
    }

    .Sidebar {
        width: 25% !important;
    }
}

@media (max-width: 1350px) {
    .Accblnce_Card, .Member_Card, .Note_Card, .Accblncee_Card {
        width: 100%;
    }

    .column-w-20 {
        width: 20%;
    }
}

@media (max-width: 1000px) {
    .portal-tab-container {
        width: 100% !important;
    }

    .Sidebar {
        position: fixed;
        display: flex;
        visibility: hidden;
        transform: translateX(-100%);
        z-index: 1045;
        width: 30% !important;
        background: var(--primary-color);
        color: var(--secondary-color);
        overflow-y: auto;
        padding-bottom: 5%;
    }

    .Logo {
        width: 100%;
    }

    .Logo > button {
        display: block;
        width: auto;
        margin-left: 20%;
        background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") center no-repeat;
        opacity: 1;
    }

    .vertical-nav-button {
        display: block;
    }
}

@media (max-width: 850px) {
    .Sidebar {
        width: 40% !important;
    }
}

@media (max-width: 800px) {
    .carrier-column, .status-column {
        text-align: center;
        margin: auto;
    }

    .status-column > .BlueIcon {
        padding: 0;
    }

    .carrier-column > span, .status-column > span {
        display: none;
    }

    .status-detail-container {
        display: flex;
        margin-top: 5%;
    }

    .status-detail-container > div {
        width: 20%;
        margin-right: 5%;
    }
}

@media (max-width: 700px) {
    .Sidebar {
        width: 50% !important;
    }
}

@media (max-width: 600px) {
    .Cd_Section, .Card_Section {
        display: block;
    }

    .Member_Card, .Note_Card {
        margin: 5% 0 0;
    }

    .Accblnce_Card, .Member_Card, .Note_Card, .Accblncee_Card {
        height: 20vh;
    }

    .Accblnce_Card > div:last-child, .Accblncee_Card > div:last-child {
        align-self: center;
    }

    .Refill_Btn {
        margin: 0;
    }

    #homeOrderResultModal > .modal-dialog > .modal-content {
        width: 85vw !important;
    }

    .desktop-column {
        display: none;
    }

    .status-detail-container > div {
        width: 50%;
    }

    .Graph {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .Sidebar {
        width: 75% !important;
    }
}

@media (max-width: 400px) {
    .Sidebar {
        width: 100% !important;
    }

    .Logo {
        width: 85%;
    }

    .Receno-Barr {
        display: block;
    }

    .Plcenew_Btn {
        margin-top: 5%;
    }
}

@media (max-width: 350px) {
    .tab-column {
        display: none;
    }

    .invoice-amount-column > svg {
        display: block;
    }
}
