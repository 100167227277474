.Customexport_Btn {
    width: 10%;
}

.Search_Bbox {
    background: #FFFFFF;
    border: 1px solid #EBEFF2;
    border-radius: 0;
    width: 90%;
    margin-right: 2%;
}

.Search_Bbox > input {
    width: 100%;
    padding: 0 4%;
}

.Search_Icon {
    height: 16px;
    width: 16px;
    position: absolute;
    margin: 0.75% 0.5%;
}

.Search_Txt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #818C99;
}

.order-logo {
    width: 25px;
    height: 25px;
    border-radius: 0;
    margin-right: 5%;
}

.Customexport_Btn:disabled {
    opacity: 0.5;
    cursor: default;
}

.export-button {
    border-radius: 0 !important;
    margin-right: 5%;
    margin-bottom: 2%;
    border: 2px solid #007BFF;
}

.search-component {
    display: flex;
}

.product-form {
    display: flex;
}

.add-product-form > .QuantityBox {
    width: 30%;
}

.add-product-form > .Dropdown_Box {
    width: 30%;
}

.model-body > .dropdowntxt {
    margin-top: 5%;
}

@media (max-width: 700px) {
    .Search_Bbox {
        width: 70%;
    }

    .Search_Icon {
        margin: 3% 2%;
    }

    .Search_Bbox > input {
        padding: 0 10%;
    }

    .Customexport_Btn {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .product-form {
        display: block;
    }

    .QuantityBox, .Dropdown_Box {
        width: 100% !important;
        height: 46px;
        margin-top: 2%;
    }

    .Dropdown_Box {
        height: 100%;
    }

    .Ordr_Btn {
        width: 48%;
        margin-top: 5%;
        margin-right: 2%;
        padding: 3%;
    }

    .product-form > .Ordr_Btn:last-child {
        margin-left: 2% !important;
        margin-right: 0;
    }

    .product-form > .order-now-button:last-child {
        width: 100%;
        margin: 2% 0 0 !important;
    }
}

@media (max-width: 400px) {
    .search-component {
        display: block;
    }

    .search-component > * {
        width: 100%;
    }

    .search-component > button {
        margin-top: 5%;
    }
}
