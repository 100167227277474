.main {
    text-align: center;
    background-color: #1E1E1E;
    min-height: 100vh;
}

.main > .d-flex > div:first-child {
    width: 70%;
    background: url("../images/login.png") top no-repeat;
    background-size: cover;
}

.main > .d-flex > div:last-child {
    width: 30%;
    background: #F7FAFC;
    min-height: 100vh;
    padding: 10% 5%;
}

.img {
    /* Emoji -> */
    padding: 0 0 5%;
    width: 100%;
}

.peoples-wireless {
    color: #27272E;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
}

.loremIpsum {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #27272E;
    padding-top: 0;
}

.usernametxt {
    /* Forms/Label */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Text/Body/Light */
    color: #425466;
    padding: 0 1%;
    margin: 5% 0;
    height: 17px;
}

.passtxt {
    /* Forms/Label */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Text/Body/Light */
    color: #425466;
    padding: 0 1%;
    margin: 5% 0;
    height: 17px;
}

.signinbtnn {
    width: 100%;
    height: 46px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    border-width: 0;
    margin-top: 15%;
}

.login-alert {
    margin: 5% 0 -10%;
}

@media (max-height: 800px) {
    .signinbtnn {
        margin-bottom: 5%;
    }
}

@media (max-width: 1400px) {
    .main > .d-flex > div:first-child {
        width: 60%;
    }

    .main > .d-flex > div:last-child {
        width: 40%;
    }
}

@media (max-width: 1000px) {
    .main > .d-flex > div:first-child {
        width: 50%;
    }

    .main > .d-flex > div:last-child {
        width: 50%;
    }
}

@media (max-width: 700px) {
    .main > .d-flex {
        display: block !important;
        background: url("../images/login.png") top no-repeat;
        background-size: cover;
        min-height: 100vh;
    }

    .main > .d-flex > div:first-child {
        display: none;
    }

    .main > .d-flex > div:last-child {
        margin: auto;
        min-height: 50vh;
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
    }

    .main > .d-flex > div:last-child > form > .img > img {
        width: 30%;
    }
}

@media (max-width: 500px) {
    .main > .d-flex > div:last-child {
        width: 100%;
        top: 15%;
        left: 0;
    }
}

