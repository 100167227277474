.ManMyUser {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #27272E;
}

.Manage_Btn {
    width: 75%;
    background: #F0F7FF;
    border-radius: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #007BFF;
    padding: 5px 12px;
    border-width: 0;
}

.Manage_Btn:hover {
    background: #007BFF;
    color: white;
}

.pagination-bar {
    margin: 2% 0;
    padding: 0;
}

@media (max-width: 1400px) {
    .Manage_Btn {
        width: 100%;
    }
}

